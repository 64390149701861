import { Navigate, useSearchParams, type RouteSectionProps } from "@solidjs/router";
import {
  createEffect,
  createResource,
  type JSXElement,
  Match,
  Suspense,
  Switch,
  type Component,
  Show,
  batch,
} from "solid-js";
import { createStore } from "solid-js/store";
import { useIsIdentityConnecting } from "~/domains/identity/hooks";
import styles from "./AcceptInviteScreen.module.css";
import { useWire } from "~/wire";
import { urls } from "~/lib/urls";
import { SkipToContent } from "~/components/cta/SkipToContent";
import { LandingPageNavbar } from "~/domains/marketing/components/LandingPageNavbar";
import { GradientBorder } from "~/domains/chat/prompt/components/EditorFocusedAnimation";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { TbRefresh } from "solid-icons/tb";
import { Spinner } from "~/components/_original/Spinner";
import { getRequestClient } from "@repo/client";

enum TokenError {
  INVALID = "INVALID",
  NOT_FOUND = "NOT_FOUND",
  EXPIRED = "EXPIRED",
  ALREADY_ACCEPTED = "ALREADY_ACCEPTED",
  UNKNOWN = "UNKNOWN",
}

const validateToken = (token: string | string[] | undefined): TokenError | undefined => {
  if (Array.isArray(token)) return TokenError.INVALID;
  if (!token) return TokenError.NOT_FOUND;
  return undefined;
};

export const AcceptInviteScreen: Component<RouteSectionProps> = () => {
  const wire = useWire();
  const isConnecting = useIsIdentityConnecting();
  const isGuest = () => wire.services.identity.snapshot.context.identity.isGuest;
  const [params] = useSearchParams();
  const [state, setState] = createStore({
    token: undefined as string | undefined,
    error: undefined as TokenError | undefined,
  });

  createEffect(() => {
    const error = validateToken(params.token);
    setState({
      token: error ? undefined : (params.token as string),
      error,
    });
  });

  return (
    <>
      <SkipToContent />
      <LandingPageNavbar static />

      <main id="main-content">
        <Switch>
          <Match when={state.error}>{(error) => <AcceptInviteError refetch={() => {}} error={error()} />}</Match>
          <Match when={isConnecting()}>
            <AcceptInviteCard
              title={
                <>
                  <Spinner />
                  Checking invite
                </>
              }
              subtitle="Please wait..."
            />
          </Match>
          <Match when={isGuest()}>
            <Navigate href={urls.signUp(state.token)} />
          </Match>
          <Match when={state.token}>
            <AcceptInviteGated token={state.token as string} />
          </Match>
        </Switch>
      </main>
    </>
  );
};

// This component should only be rendered if the user is logged in
// with a non-guest account and the token is valid
const AcceptInviteGated: Component<{ token: string }> = (props) => {
  const wire = useWire();
  const client = getRequestClient(wire.services.identity.getIdentityToken);

  const [data, { refetch }] = createResource(
    () => props.token,
    async (token): Promise<{ error?: TokenError | string; orgId?: string }> => {
      // TODO: Call controlplane api to accept
      const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

      try {
        const res = await client.controlplane.ClaimToken(token);
        if (res.data.successfulGrants.length === 0) {
          return { error: TokenError.UNKNOWN };
        }
        batch(() => {
          for (const tree of res.data.trees) {
            wire.services.collections.setTree(tree);
          }
        });

        return { orgId: res.data.successfulGrants[0] };
      } catch (error) {
        return { error: TokenError.UNKNOWN };
      }

      // await sleep(4000);

      // const expired = false;
      // const alreadyAccepted = false;
      // const otherError = false;
      // if (expired) {
      //   return { error: TokenError.EXPIRED };
      // }

      // if (alreadyAccepted) {
      //   return { error: TokenError.ALREADY_ACCEPTED, orgId: "collection_cti85hnsg8441l60k2sg", orgName: "Acme" };
      // }

      // if (otherError) {
      //   return { error: TokenError.UNKNOWN };
      // }

      // return { orgId: "collection_cti85hnsg8441l60k2sg", orgName: "Acme" };
    },
  );
  return (
    <Suspense
      fallback={
        <AcceptInviteCard
          title={
            <>
              <Spinner />
              Accepting invite
            </>
          }
          subtitle="Please wait..."
        />
      }
    >
      <Switch>
        <Match when={data()?.error}>{(error) => <AcceptInviteError refetch={refetch} error={error()} />}</Match>
        <Match when={data()?.orgId}>{(orgId) => <Navigate href={urls.collection(orgId())} />}</Match>
      </Switch>
    </Suspense>
  );
};

const AcceptInviteCard: Component<{ title: JSXElement; subtitle: string; actions?: JSXElement }> = (props) => {
  return (
    <div class={styles["error-card"]}>
      <div class={styles["error-card__title"]}>{props.title}</div>
      <div class={styles["error-card__subtitle"]}>{props.subtitle}</div>

      {props.actions}
      <GradientBorder />
    </div>
  );
};

const AcceptInviteError: Component<{ error: TokenError | string; refetch: () => void }> = (props) => {
  const title = () => {
    switch (props.error) {
      case TokenError.NOT_FOUND:
      case TokenError.INVALID:
        return "This invite is invalid";
      case TokenError.EXPIRED:
        return "This invite has expired";
      case TokenError.ALREADY_ACCEPTED:
        return "You've already accepted this invite";
      default:
        return "Something went wrong";
    }
  };

  const subtitle = () => {
    switch (props.error) {
      case TokenError.NOT_FOUND:
      case TokenError.INVALID:
        return "Please make sure you copied the invite link correctly.\nContact the sender if this issue persists.";
      case TokenError.EXPIRED:
        return "Please contact the sender to get a new invite link.";
      case TokenError.ALREADY_ACCEPTED:
        return "We'll redirect you to the organization you've been invited to in 5 seconds.";
      default:
        return "Double check the invite link or try again.\nIf the error persists, please contact the sender or our support team.";
    }
  };
  return (
    <AcceptInviteCard
      title={title()}
      subtitle={subtitle()}
      actions={
        <Switch>
          <Match when={props.error === TokenError.ALREADY_ACCEPTED}>
            <div class={styles["error-card__actions"]}>
              <div aria-hidden />
              <SecondaryCTA
                data-test-id="already-accepted-redirect-to-home"
                accessiblePrefix="Or click to "
                label="Redirect me right away"
              />
            </div>
          </Match>
          <Match when={props.error === TokenError.UNKNOWN}>
            <div class={styles["error-card__actions"]}>
              <div aria-hidden />
              <SecondaryCTA
                icon={TbRefresh}
                data-test-id="unknown-error-retry"
                accessiblePrefix="Click to "
                label="Retry"
                onClick={props.refetch}
              />
            </div>
          </Match>
        </Switch>
      }
    />
  );
};
