import type { curator, operation } from "@repo/client";
import type { SetStoreFunction } from "solid-js/store";

export enum CollectionKind {
  Personal = "personal_root",
  Favorites = "favorites_root",
  Org = "organization_root",
  OrgChild = "organization_child",
}

/**
 * Internal structure for the global collections store
 *
 * `ids` keeps a record of each collection's data, keyed by id
 *
 * `childOf` keeps a record of each what each collection is a child of (by id)
 *
 * `parentOf` keeps a record of each what each collection is a parent of (by id)
 */
export type CollectionsStore = {
  ids: CollectionsByIdRecord;
  parentOf: CollectionHierarchyRecord;
  childOf: CollectionHierarchyRecord;
  root: Partial<Record<CollectionKind, string>>;
  ui: {
    rootCollectionsLoaded: boolean;
  };
};

export type SetCollectionsStoreFunc = SetStoreFunction<CollectionsStore>;

export type CollectionSnapshot = curator.CollectionSnapshot;
export type CollectionTree = curator.CollectionTree;
export type CollectionHierarchyRecord = Record<string, string[]>;
export type CollectionsByIdRecord = Record<string, CollectionSnapshot>;
export type CollectionAccess = curator.GetCollectionAccessResult;
export type CollectionAccessRecord = curator.GetCollectionAccessRecord;
export type CollectionsResourceRefetch = (
  info?: unknown,
) =>
  | operation.Response<curator.CollectionTrees>
  | Promise<operation.Response<curator.CollectionTrees> | undefined>
  | null
  | undefined;
/**
 * Same as `CollectionTree` but with ids instead of `CollectionSnapshot`s
 */
export type CollectionTreeIds = {
  collection: string;
  children: CollectionTreeIds[];
};
