import type { curator, operation } from "@repo/client";
import { TbFolderFilled } from "solid-icons/tb";
import {
  type Component,
  Show,
  type Signal,
  Suspense,
  createEffect,
  createSignal,
  on,
  onCleanup,
  onMount,
  startTransition
} from "solid-js";
import { CollectionAssetsTable } from "~/components/CollectionAssetTable";
import { LargeFileUpload } from "~/components/LargeFileUpload";
import { Loading } from "~/components/Loading";
import { Pagination } from "~/components/Pagination";
import { SectionHeader } from "~/components/SectionHeader";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { ChatFileUpload } from "~/domains/chat/prompt/components/ChatFileUpload";
import type { CollectionSnapshot } from "~/domains/collections/collections.types";
import { createListResourceOptions } from "~/lib/api/createListResourceOptions";
import { parseAssetsListResponse } from "~/lib/api/parseAssetListResponse";
import { ProcessingReadableState, readableProcessingState } from "~/lib/ui/readableProcessingState";
import { useUIState } from "~/ui/UIState";

export const CollectionAssetsScreen: Component<{
  collection: CollectionSnapshot | undefined;
  assetResponse: operation.Response<curator.CollectionAssets> | undefined;
  refreshAssets: () => void;
  listOptions: Signal<ListResourceOptions>;
}> = (props) => {
  const state = useUIState();
  const [tab, setTab] = state.mainContentTab;
  const pageSignal = createSignal(1);
  const [page, setPage] = pageSignal;
  const [, setAssetsListOptions] = props.listOptions;
  createEffect(on(page, () => {
    startTransition(() => {
      setAssetsListOptions(createListResourceOptions(page()));
    });
  }));

  let canPoll = false;
  let timeout: ReturnType<typeof setTimeout> | undefined;
  const poll = () => {
    if (
      parseAssetsListResponse(props.assetResponse).filter(
        (asset) =>
          readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Ready &&
          readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Failed,
      ).length > 0
    ) {
      startTransition(() => {
        props.refreshAssets();
      });
      if (canPoll) {
        timeout = globalThis.setTimeout(poll, 3000);
      }
    } else {
      timeout = undefined;
    }
  };

  createEffect(() => {
    if (tab() === "Assets" && !timeout) {
      poll();
    }
  });

  onMount(() => {
    poll();
    canPoll = true;
    onCleanup(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      canPoll = false;
    });
  });

  const [assetUploadModal, setAssetUploadModal] = createSignal(false);
  return (
    <Suspense fallback={<Loading />}>
      <ChatFileUpload
        context="project"
        open={assetUploadModal()}
        setOpen={setAssetUploadModal}
        onUploaded={() => {
          startTransition(() => {
            props.refreshAssets();
          });
          globalThis.setTimeout(poll, 2000);
          setTab("Assets");
        }}
      />
      <ContentContainer>
        <LargeFileUpload showUploadModal={() => setAssetUploadModal(true)} />
      </ContentContainer>
      <Show when={parseAssetsListResponse(props.assetResponse).length}>
        <ContentContainer>
          <SectionHeader title={`${props.assetResponse?.data.pagination?.count} assets in this Collection`} icon={TbFolderFilled} />
          <CollectionAssetsTable collectionName={props.collection?.label || ""} data={parseAssetsListResponse(props.assetResponse)} />
          <Show when={props.assetResponse?.data.pagination?.totalPages ? props.assetResponse?.data.pagination?.totalPages > 1 : false}>
            <Pagination page={pageSignal} totalPages={props.assetResponse?.data.pagination.totalPages} totalCount={props.assetResponse?.data.pagination.count} />
          </Show>
        </ContentContainer>
      </Show>
    </Suspense>
  );
};
