import { stAnalytics } from "@repo/analytics";
import { captureException } from "@repo/observability";
import { BrowserStorage } from "@repo/storage";
import { useSearchParams } from "@solidjs/router";
import { TbBrandGoogle, TbSparkles } from "solid-icons/tb";
import { Show, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import { EmailField } from "~/components/_original/EmailField";
import { OrDivider } from "~/components/_original/OrDivider";
import { StButton } from "~/components/_original/StButton";
import { useWire } from "~/wire";

const LogIn = () => {
  const wire = useWire();
  const [params] = useSearchParams();

  const invite = () => (typeof params.invite === "string" ? params.invite : undefined);

  const [state, setState] = createStore({
    email: "",
    status: "idle" as "idle" | "loading" | "sent",
  });

  const onMagicLinkSignIn = (e: SubmitEvent) => {
    e.preventDefault();
    setState("status", "loading");

    wire.services.identity
      .signInWithMagicLink(state.email, invite())
      .then(() => {
        setState("status", "sent");
      })
      .catch((err) => {
        captureException(err);
        stAnalytics.track("error_auth", {
          email: state.email,
          error_source: "login",
          flow: "magic-link",
        });
        setState("status", "idle");
      });

    stAnalytics.track("log_in_started", {
      flow: "magic-link",
      email: state.email,
    });
  };
  const onGoogleLogin = async () => {
    try {
      // awaiting so that the redirect doesn't happen before we send out the event
      await stAnalytics.track("log_in_started", {
        flow: "google",
      });
    } catch (error) {
      captureException(error);
      stAnalytics.track("error_auth", {
        email: state.email,
        error_source: "login",
        flow: "google",
      });
    }

    /**
     * When signing in with google, we don't have a way to pass the invite token and get it back
     * from the OAUTH flow like with the magic link. Luckily, if the invite token is present in
     * the URL, the OAUTH flow will redirect back to the same url including the invite token.
     *
     * We then intercept that redirect and navigate to the accept invite page.
     * See the mounting logic in apps/webconsole/src/routes/auth.tsx
     */
    wire.services.identity.signInWithGoogle();
  };

  onMount(() => {
    const email = BrowserStorage.getLastUsedMagicLinkEmail();
    if (email) setState("email", email);
  });

  const title = () => {
    if (state.status === "sent") {
      return "We’ve sent you a magic link!";
    }
    if (invite()) {
      return "You've been invited to join an organization on Storytell!";
    }
    return "Log in to your account";
  };

  return (
    <div class="flex flex-col items-center gap-4 sm:min-w-96">
      <div class="w-full">
        <h1 class="text-xl font-semibold bg-clip-text text-transparent bg-text-gradient-dark dark:bg-text-gradient inline">
          {title()}
        </h1>
        <Show when={invite() && state.status !== "sent"}>
          <p class="text-lg text-gray-800 dark:text-gray-300 leading-normal mt-4">Log in to accept your invite</p>
        </Show>
      </div>

      <Show
        when={state.status !== "sent"}
        fallback={<div class="pt-10 pb-5 text-base leading-normal w-full">You can close this page.</div>}
      >
        <form class="pb-5 pt-10 w-full flex flex-col sm:flex-row gap-4 items-end" onSubmit={onMagicLinkSignIn}>
          <EmailField
            withIcon
            email={state.email}
            setEmail={(value) => setState("email", value)}
            inputProps={{ autofocus: true }}
          />

          <StButton
            icon={TbSparkles}
            type="submit"
            loading={state.status === "loading"}
            disabled={state.status === "loading"}
            class="flex-shrink-0 w-full sm:w-auto"
          >
            Email me a Magic Link
          </StButton>
        </form>

        <OrDivider />

        <div class="flex items-center gap-4 py-5">
          <StButton
            disabled={state.status === "loading"}
            onClick={onGoogleLogin}
            iconClass="stroke-4"
            icon={TbBrandGoogle}
          >
            Log in with Google
          </StButton>
        </div>
      </Show>
    </div>
  );
};

export default LogIn;
