import { useNavigate } from "@solidjs/router";
import { TbRefresh, TbUsersGroup } from "solid-icons/tb";
import { type Component, ErrorBoundary, For, Match, Show, startTransition, Suspense, Switch } from "solid-js";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { Loading } from "~/components/Loading";
import { SidebarCollection } from "~/components/SideBarCollection";
import { SideBarSectionAccordion } from "~/components/SideBarSectionAccordion";
import { getRootCollectionUIOverloads } from "~/domains/collections/collections.helpers";
import { CollectionKind } from "~/domains/collections/collections.types";
import { urls } from "~/lib/urls";
import { CreateSharedCollectionModal } from "~/screens/modals/CreateSharedCollectionModal";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import styles from "./SidebarCollections.module.css";

export const SidebarCollections: Component = () => {
  const wire = useWire();
  const collections = wire.services.collections;
  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;

  const roots = () => (!collections.getRootsLoaded() ? [] : collections.getRootCollections());

  const sharedRoot = () => roots().find((r) => r.collectionKind === CollectionKind.Org);
  const sharedCollections = () => {
    return collections.getChildrenIds(sharedRoot()?.id || "");
  };

  return (
    <Suspense fallback={<Loading />}>
      <CreateSharedCollection hasShared={sharedCollections().length !== 0} sharedRootId={sharedRoot()?.id || ""} />
      <ErrorBoundary
        fallback={
          <div class={styles["sidebar-collections__error-boundary"]}>
            <p class={styles["sidebar-collections__error-boundary-text"]}>
              There was an error loading your collections.
            </p>
            <div>
              <SecondaryCTA
                size="small"
                data-test-id="sidebar-collections-error-boundary"
                accessiblePrefix="Click to "
                icon={TbRefresh}
                label="Reload"
                onClick={() => startTransition(() => refetchTree())}
              />
            </div>
          </div>
        }
      >
        <Show when={collections.getRootsLoaded() && tree()} fallback={<Loading />}>
          <For each={roots()}>
            {(root) => (
              <Switch>
                <Match when={root.collectionKind === CollectionKind.Personal}>
                  <SidebarCollection
                    onCollectionCreated={refetchTree}
                    root
                    collectionId={root.id}
                    depth={0}
                    refreshTree={refetchTree}
                  />
                </Match>
                <Match when={collections.getChildrenIds(root.id).length === 0}>{null}</Match>

                <Match when={true}>
                  <SideBarSectionAccordion label={getRootCollectionUIOverloads(root).label} initiallyOpen depth={0}>
                    <For each={collections.getChildrenIds(root.id)}>
                      {(child) => (
                        <SidebarCollection
                          onCollectionCreated={refetchTree}
                          collectionId={child}
                          depth={0}
                          refreshTree={refetchTree}
                        />
                      )}
                    </For>
                  </SideBarSectionAccordion>
                </Match>
              </Switch>
            )}
          </For>
        </Show>
      </ErrorBoundary>
    </Suspense>
  );
};

const CreateSharedCollection: Component<{ sharedRootId: string; hasShared: boolean }> = (props) => {
  const wire = useWire();
  const state = useUIState();
  const [, setSidebarOpen] = state.leftDrawer;
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  const [, setOpenShareDrawer] = state.openShareDrawer;
  const navigate = useNavigate();

  return (
    <PrimaryCTA
      data-test-id="sidebar-start-collaborating"
      icon={TbUsersGroup}
      accessiblePrefix="Click to "
      label={props.hasShared ? "New shared collection" : "Start collaborating"}
      size={props.hasShared ? "small" : "medium"}
      class="mt-3"
      onClick={() => {
        setModalContents(() => () => (
          <CreateSharedCollectionModal
            id="create-shared-collection-modal"
            onCreate={async (name, description) => {
              const res = await wire.services.collections.apiCreateCollection(
                wire.services.collections.getDataOrFail(props.sharedRootId),
                name,
                description,
              );
              setSidebarOpen(false);
              setOpenShareDrawer(true);
              navigate(urls.collection(res.data.id));

              setModalOpen("");
              setModalContents(null);
            }}
          />
        ));
        setModalOpen("create-shared-collection-modal");
      }}
    />
  );
};
