import { createUniqueId, type JSXElement, Match, Show, Switch } from "solid-js";
import { PseudoSelect } from "~/components/fields/PseudoSelect";
import { InlineUserAvatar } from "~/components/InlineUserAvatar";
import type { UserAvatarProps } from "~/components/UserAvatar";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import styles from "./EditUserPermissions.module.css";
import { StIcon } from "./icons";
import { TbMail } from "solid-icons/tb";
import { SecondaryCTA } from "./cta/SecondaryCTA";
import { A } from "@solidjs/router";
import { urls } from "~/lib/urls";
import { classNames } from "~/lib/classNames";
import { useWire } from "~/wire";
import { getRootCollectionUIOverloads } from "~/domains/collections/collections.helpers";

export type EditUserPermissionsOptions = {
  value: string;
  label: string;
  divider?: boolean;
  onClick?: () => void;
}[];

type Props = {
  avatar?: UserAvatarProps;
  email?: string;
  value?: string;
  onChange?: () => void;
  options: EditUserPermissionsOptions;
  actions?: JSXElement;
  disabled?: boolean;
  inherits?: {
    collectionId: string;
    label: string;
  };
};

export const EditUserPermissions = (props: Props) => {
  const id = `edit-user-permissions-${createUniqueId()}`;
  const wire = useWire();
  const canNavigateToCollection = () =>
    props.inherits ? wire.services.collections.getCanNavigateToCollection(props.inherits.collectionId) : false;
  const collectionLabel = () =>
    props.inherits
      ? getRootCollectionUIOverloads(wire.services.collections.getCollection(props.inherits.collectionId))?.label
      : "";

  return (
    <div class={classNames(styles["edit-user-permissions-container"])}>
      <div class={styles["edit-user-permissions"]}>
        <Switch>
          <Match when={props.email}>
            {(email) => (
              <div class={styles["edit-user-permissions__email"]}>
                <StIcon class={styles["edit-user-permissions__email-icon"]} icon={TbMail} />
                <span class={styles["edit-user-permissions__email-name"]}>{email()}</span>
              </div>
            )}
          </Match>
          <Match when={props.avatar}>
            {(avatar) => (
              <div class={styles["edit-user-permissions__avatar"]}>
                <InlineUserAvatar
                  {...avatar()}
                  subtitle={
                    <Show when={props.inherits}>
                      {(inherits) => (
                        <div class={styles["edit-user-permissions-inherits"]}>
                          <span>From </span>
                          <A
                            href={canNavigateToCollection() ? urls.collection(inherits().collectionId) : ""}
                            class={classNames(
                              styles["edit-user-permissions-inherits__label"],
                              !canNavigateToCollection()
                                ? styles["edit-user-permissions-inherits__label--disabled"]
                                : "",
                            )}
                          >
                            {collectionLabel()}
                          </A>
                        </div>
                      )}
                    </Show>
                  }
                />
              </div>
            )}
          </Match>
        </Switch>

        <div class={styles["edit-user-permissions__actions"]}>
          <Show
            when={!props.disabled}
            // fallback={
            //   <span class={styles["edit-user-permissions__disabled-label"]}>
            //     {props.options.find((o) => o.value === props.value)?.label ?? "No access"}
            //   </span>
            // }
          >
            <PseudoSelect
              name={id}
              value={props.value ?? ""}
              placeholder="No access"
              modifier="thin"
              required={true}
              onChange={props.onChange}
              portal={document.getElementById(GLOBAL_ELEMENT_IDS.rightDrawer) ?? document.body}
              options={props.options}
            />
          </Show>
          {props.actions}
        </div>
      </div>
    </div>
  );
};
