import { Show } from "solid-js/web";
import { StIcon, type StIconComponent } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./SectionHeader.module.css";

interface Props {
  title: string;
  icon?: StIconComponent;
  expandLabel?: string;
  modifier?: "thin-bottom-padding" | "thin-top-padding";
}
export const SectionHeader = (props: Props) => {
  return (
    <p class={classNames(styles["section-header"], props.modifier ? styles[`section-header--${props.modifier}`] : "")}>
      <Show when={props.icon}>
        <span class={styles["section-header__icon-container"]}>
          <StIcon icon={props.icon!} class={styles["section-header__icon"]} size="1.5rem" />
        </span>
      </Show>
      <span class={classNames(styles["section-header__title"])}>{props.title}</span>
      <Show when={props.expandLabel}>
        <span class={styles["section-header__button-container"]}>
          <button
            type="button"
            class={styles["section-header__button"]}
            aria-label={`Toggle ${props.title} section.`}
            tabIndex={0}
          >
            <span class={styles["section-header__button-label"]}>{props.expandLabel}</span>
          </button>
          <button
            type="button"
            class={styles["section-header__button--mobile"]}
            aria-label={`Toggle ${props.title} section.`}
            tabIndex={0}
          >
            <span class={styles["section-header__button-label"]}>+</span>
            <span class="screen-reader">{props.expandLabel}</span>
          </button>
        </span>
      </Show>
    </p>
  );
};
