import { captureException } from "@repo/observability";
import { TbSend, TbUserPlus } from "solid-icons/tb";
import { createEffect, createSignal, For, onCleanup, onMount, type Component } from "solid-js";
import type { CollectionAccessRecord } from "~/domains/collections/collections.types";
import type { AuthenticatedIdentity } from "~/domains/identity/types";
import { useFormValidation, type FormValidationData } from "~/ui/useFormValidation";
import { useWire } from "~/wire";
import { PrimaryCTA } from "../cta/PrimaryCTA";
import { SecondaryCTA } from "../cta/SecondaryCTA";
import { EditUserPermissions, type EditUserPermissionsOptions } from "../EditUserPermissions";
import type { UserAvatarProps } from "../UserAvatar";
import { LargeInputText } from "./LargeInputText";
import styles from "./UsersTagsList.module.css";

export type UsersTagListTag = RequireAtLeastOne<
  { id: string; avatar?: UserAvatarProps; email?: true },
  "avatar" | "email"
>;

export const UsersTagsList: Component<{
  sendInvites: (tags: UsersTagListTag[]) => Promise<void>;
  options: EditUserPermissionsOptions;
  access: CollectionAccessRecord[] | undefined;
}> = (props) => {
  const wire = useWire();
  const [tags, setTags] = createSignal<UsersTagListTag[]>([]);
  const addTag = (tag: UsersTagListTag) => {
    setTags([...tags(), tag]);
  };
  const removeTag = (tag: UsersTagListTag) => {
    setTags(tags().filter((t) => t.id !== tag.id));
  };
  const searchTags = async (search: string) => {
    return [];
  };

  const [error, setError] = createSignal<string | undefined>(undefined);

  const [form, setForm] = createSignal((<></>) as HTMLFormElement);
  const [inputValue, setInputValue] = createSignal("");
  const [data, setData] = createSignal<FormValidationData>();
  const [canSubmit, setCanSubmit] = createSignal(false);
  const [isSending, setIsSending] = createSignal(false);

  onMount(() => {
    if (form()) {
      const { validates, data, eventHandler } = useFormValidation(form());
      form().addEventListener("input", eventHandler);
      form().addEventListener("change", eventHandler);
      createEffect(() => {
        setCanSubmit(validates());
        setData(data);
      });

      onCleanup(() => {
        form().removeEventListener("input", eventHandler);
        form().removeEventListener("change", eventHandler);
      });
    }
  });

  const handleSubmit = async (event: SubmitEvent) => {
    event.stopImmediatePropagation();
    event.preventDefault();

    if (event.target instanceof HTMLFormElement) {
      const d = data();
      const email = d?.values.email;

      if (email === (wire.services.identity.snapshot.context.identity as AuthenticatedIdentity).email) {
        setError("You cannot invite yourself");
        return;
      }

      if (props.access?.some((a) => a.email === email)) {
        setError("You cannot invite the same person twice");
        return;
      }

      addTag({ id: email || "", email: true });
      setError(undefined);
      setInputValue("");
    }
  };

  const handleSendInvites = async () => {
    setIsSending(true);
    setError(undefined);
    try {
      await props.sendInvites(tags());
      setTags([]);
    } catch (error) {
      setError("Something went wrong. Please try again.");
      captureException(error);
    }

    setIsSending(false);
  };

  return (
    <div class={styles["tags-list"]}>
      <form ref={setForm} class={styles["tags-list__form"]} onSubmit={handleSubmit}>
        <LargeInputText
          placeholder="Enter an email address"
          label="Enter an email address"
          name="email"
          validation={["email", "required"]}
          modifier="outline"
          value={inputValue()}
          showError={!!error()}
          errorMessage={error()}
          onInput={(e) => {
            setInputValue((e.currentTarget as HTMLInputElement).value);
          }}
        />
        <SecondaryCTA
          type="submit"
          class={styles["tags-list__form-submit"]}
          data-test-id="add-person"
          accessiblePrefix="Click to "
          icon={TbUserPlus}
          label="Add"
          inactive={!canSubmit() || inputValue().length === 0}
        />
      </form>

      <div class={styles["tags-list__pending"]}>
        <For each={tags()}>
          {(tag) => (
            <EditUserPermissions
              disabled
              avatar={tag.avatar}
              email={tag.email ? tag.id : undefined}
              value="collection:read"
              options={props.options}
              actions={
                <SecondaryCTA
                  data-test-id="revoke-invite"
                  accessiblePrefix="Click to "
                  label="Remove"
                  accessibleSuffix=" Person"
                  onClick={() => removeTag(tag)}
                />
              }
            />
          )}
        </For>
      </div>

      {/* TODO: Bring it back when we can send invites by mail */}
      <PrimaryCTA
        onClick={handleSendInvites}
        inactive={tags().length === 0}
        class={styles["tags-list__send-invites"]}
        data-test-id="send-invites"
        accessiblePrefix="Click to "
        icon={TbSend}
        loading={isSending()}
        label="Send invites"
      />
    </div>
  );
};
