import { Motion, Presence } from "@repo/solid-motionone";
import { TbArrowBackUp, TbArrowForwardUp, TbHash, TbPaperclip, TbSlash } from "solid-icons/tb";
import { type Component, type JSX, type ParentComponent, Show } from "solid-js";
import { KeyboardShortcutPill } from "~/components/_original/KeyboardShortcutPill";
import { StButton } from "~/components/_original/StButton";
import { Tooltip, TooltipTrigger } from "~/components/_original/Tooltip";
import type { StIconComponent } from "~/components/icons";
import { selectIsIdentityConnecting } from "~/domains/identity/hooks";
import { useWire } from "~/wire";
import { usePromptContext } from "../PromptContext";

export const ChatBottomBar: Component = () => {
  const { focused, editor, setShowUploadModal } = usePromptContext();
  const wire = useWire();

  return (
    <Presence exitBeforeEnter>
      <Show when={focused()}>
        <Motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: 1,
            height: "2.25rem",
          }}
          exit={{ opacity: 0, height: 0 }}
          class="relative overflow-y-hidden"
        >
          <div class="flex items-center gap-2 absolute bottom-0 left-0 right-0 text-2xs text-gray-600 dark:text-gray-300 rounded-b px-2 py-2">
            <ToolbarButton
              label="Slash command"
              onClick={() => editor()?.commands.invokeSlashCommand()}
              icon={TbSlash}
            />
            <ToolbarButton
              label="Upload files"
              disabled={selectIsIdentityConnecting(wire.services.identity)}
              onClick={() => {
                setShowUploadModal(true);
              }}
              icon={TbPaperclip}
            />
            <ToolbarButton label="Specify a Collection" onClick={() => editor()?.commands.invokeTag()} icon={TbHash} />
            <ToolbarButton
              label="Previous prompt"
              tooltip={
                <p>
                  Previous prompt{" "}
                  <KeyboardShortcutPill
                    keys={["ArrowUp"]}
                    class="ml-1 px-1.5 py-0 border text-gray-100 dark:text-slate-900 bg-slate-800 dark:bg-slate-300 border-slate-500 dark:border-slate-400 text-sm leading-6 font-black"
                  />
                </p>
              }
              onClick={() => {
                wire.services.inputHistory.getPrevious(editor());
                editor()?.commands.focus("end");
              }}
              icon={TbArrowBackUp}
              disabled={wire.services.inputHistory.isFirst()}
            />
            <ToolbarButton
              label="Next prompt"
              tooltip={
                <span>
                  Next prompt{" "}
                  <KeyboardShortcutPill
                    keys={["ArrowDown"]}
                    class="ml-1 px-1.5 py-0 border text-gray-100 dark:text-slate-900 bg-slate-800 dark:bg-slate-300 border-slate-500 dark:border-slate-400 text-sm leading-6 font-black"
                  />
                </span>
              }
              onClick={() => {
                wire.services.inputHistory.getNext(editor());
                editor()?.commands.focus("end");
              }}
              icon={TbArrowForwardUp}
              disabled={wire.services.inputHistory.isLast()}
            />
            {/* <ToolbarButton
              label="Mention someone"
              onClick={() => editor()?.commands.invokeMention()}
              icon={TbAt}
            /> */}

            {/* <UpcomingFeature
              label="Prompt Format Text Button"
              mount={promptRef()}
            >
              <ToolbarButton label="Format Text" icon={TbTypography} />
            </UpcomingFeature> */}
          </div>
        </Motion.div>
      </Show>
    </Presence>
  );
};

const ToolbarButton: ParentComponent<{
  label: string;
  tooltip?: JSX.Element;
  onClick?: () => void;
  icon: StIconComponent;
  disabled?: boolean;
}> = (props) => {
  return (
    <Tooltip theme="invert" placement="top" content={props.tooltip ?? <p>{props.label}</p>}>
      <TooltipTrigger as="span">
        <StButton size="sm" onClick={props.onClick} icon={props.icon} disabled={props.disabled} label={props.label} />
      </TooltipTrigger>
    </Tooltip>
  );
};
