import type { Accessor } from "solid-js";
import type { WorkingContext } from "../identity/types";
import {
  createCollection,
  createCollectionsStore,
  createFetchAllCollectionAssetsResource,
  createFetchCollectionAccessResource,
  createFetchCollectionAssetsResource,
  createFetchCollectionTreeResource,
  deleteCollection,
  getAllCollectionsFlattened,
  getCanDeleteCollection,
  getCanMoveCollection,
  getCanNavigateToCollection,
  getCanRenameCollection,
  getCanShareCollection,
  getColectionBreadcrumbs,
  getCollection,
  getCollectionByKind,
  getCollectionChildren,
  getCollectionChildrenIds,
  getCollectionDescendantsIds,
  getCollectionOrFail,
  getCollectionParents,
  getCollectionParentsIds,
  getCollectionRoot,
  getCollectionTree,
  getCollectionTreeIds,
  getPersonalRootCollection,
  getRootCollections,
  getUICollectionRootsLoaded,
  moveCollection,
  renameCollection,
  updateCollection,
  updateCollectionsBatch,
  updateCollectionsFromTree,
  updateInitialCollectionsTreeBasedOnWorkingContext,
} from "./collections.store";
import type { CollectionKind, CollectionSnapshot, CollectionTree } from "./collections.types";

export const createCollectionsService = (deps: {
  getAuthToken: () => string | undefined;
  userId: Accessor<string | undefined>;
  isIdentityReady: () => boolean;
}) => {
  const [store, setStore] = createCollectionsStore();

  const tree = createFetchCollectionTreeResource(setStore, deps.getAuthToken, deps.userId, deps.isIdentityReady);
  const [, { refetch: refetchTree }] = tree;

  return {
    // TODO: better way to share resource data between components without resorting to a global resource
    // Global resources
    globalResourceTree: tree,

    // Getters
    getRootCollections: () => getRootCollections(store),
    getAllCollections: () => getAllCollectionsFlattened(store),
    getRootCollectionByKind: (kind: CollectionKind) => getCollectionByKind(store, kind),
    getRootsLoaded: () => getUICollectionRootsLoaded(store),
    getCollection: (collectionId: string) => getCollection(store, collectionId),
    getDataOrFail: (collectionId: string) => getCollectionOrFail(store, collectionId),
    getParentsIds: (collectionId: string) => getCollectionParentsIds(store, collectionId),
    getParents: (collectionId: string) => getCollectionParents(store, collectionId),
    getChildrenIds: (collectionId: string) => getCollectionChildrenIds(store, collectionId),
    getChildren: (collectionId: string) => getCollectionChildren(store, collectionId),
    getBreadcrumbs: (collectionId: string) => getColectionBreadcrumbs(store, collectionId),
    getTreeIds: (collectionId: string) => getCollectionTreeIds(store, collectionId),
    getTreeData: (collectionId: string) => getCollectionTree(store, collectionId),
    getCollectionDescendantsIds: (collectionId: string) => getCollectionDescendantsIds(store, collectionId),
    getPersonalRoot: () => getPersonalRootCollection(store),
    getCollectionRoot: (collectionId: string) => getCollectionRoot(store, collectionId),
    getCanShareCollection: (collectionId: string) => getCanShareCollection(store, collectionId),
    getCanMoveCollection: (collectionId: string) => getCanMoveCollection(store, collectionId),
    getCanDeleteCollection: (collectionId: string) => getCanDeleteCollection(store, collectionId),
    getCanRenameCollection: (collectionId: string) => getCanRenameCollection(store, collectionId),
    getCanNavigateToCollection: (collectionId: string) => getCanNavigateToCollection(store, collectionId),
    // Setters
    setCollection: (collection: CollectionSnapshot) => updateCollection(setStore, collection),
    setCollections: (collections: CollectionSnapshot[]) => updateCollectionsBatch(setStore, collections),
    setTree: (tree: CollectionTree) => updateCollectionsFromTree(setStore, tree),

    // Reactive listeners
    reactiveCollectionsFromWorkingContext: (wctx: Accessor<WorkingContext>) =>
      updateInitialCollectionsTreeBasedOnWorkingContext(setStore, wctx),

    // Server mutations
    apiCreateCollection: (parent: CollectionSnapshot, label: string, description?: string) =>
      createCollection(setStore, deps.getAuthToken, parent, label, description),
    apiUpdateCollection: (collectionId: string, label: string, description?: string) =>
      renameCollection(setStore, deps.getAuthToken, collectionId, label, description),
    apiMoveCollection: (collectionId: string, parentCollectionId: string) =>
      moveCollection(setStore, deps.getAuthToken, collectionId, parentCollectionId, refetchTree),
    apiDeleteCollection: (collection: CollectionSnapshot) =>
      deleteCollection(setStore, deps.getAuthToken, collection, refetchTree),

    // Resource creators
    resourceCollectionAssets: (collectionId: Accessor<string | undefined>, options: Accessor<ListResourceOptions>) =>
      createFetchCollectionAssetsResource(setStore, deps.getAuthToken, deps.userId, collectionId, options),
    resourceCollectionTree: () =>
      createFetchCollectionTreeResource(setStore, deps.getAuthToken, deps.userId, deps.isIdentityReady),
    resourceAllCollectionAssets: (collectionId: Accessor<string | undefined>) =>
      createFetchAllCollectionAssetsResource(store, deps.getAuthToken, deps.userId, collectionId),
    resourceCollectionAccess: (collectionId: Accessor<string | undefined>) =>
      createFetchCollectionAccessResource(setStore, deps.getAuthToken, deps.userId, collectionId),
  };
};

export type CollectionsService = ReturnType<typeof createCollectionsService>;
