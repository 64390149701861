import { IoClose } from "solid-icons/io";
import { type ParentProps, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import { Dynamic, Portal } from "solid-js/web";
import { IconCTA } from "~/components/cta/IconCTA";
import { classNames } from "~/lib/classNames";
import { useUIState } from "~/ui/UIState";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import { closeAll } from "~/ui/global-listeners";
import styles from "./RightDrawer.module.css";

interface Props extends ParentProps {
  class?: string;
  mount?: HTMLElement;
  accessibleLabel: string;
}

export const RightDrawer = (props: Props) => {
  const state = useUIState();
  const [getContent] = state.rightDrawerContents;
  const [open, setOpen] = state.rightDrawer;
  const [drawerRef, setDrawerRef] = createSignal<HTMLElement | null>(null);

  createEffect(() => {
    if (open()) {
      document.getElementById(GLOBAL_ELEMENT_IDS.rightDrawer)?.focus();
    }
  });

  onMount(() => {
    //  Bind handlers to allow clicks and escape keys in the parent element to close overlays.
    const drawer = drawerRef();
    if (drawer) {
      const { click } = closeAll(true);
      drawer.addEventListener("click", click);

      onCleanup(() => {
        drawer.removeEventListener("click", click);
      });
    }
  });

  return (
    <Portal mount={props.mount ?? document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}>
      <div
        id={GLOBAL_ELEMENT_IDS.rightDrawer}
        class={classNames(styles["right-drawer"], open() ? styles["right-drawer--open"] : "", props.class)}
        aria-hidden={!open()}
        aria-label={props.accessibleLabel}
        role="dialog"
        tabIndex={open() ? 0 : -1}
        ref={(ref) => setDrawerRef(ref)}
      >
        <section class={styles["right-drawer__content"]}>
          <IconCTA
            data-test-id="right-drawer-close"
            aria-controls={GLOBAL_ELEMENT_IDS.rightDrawer}
            aria-expanded={open()}
            class={styles["right-drawer__close"]}
            onClick={() => setOpen(false)}
            accessibleLabel="Close the overlay."
            modifiers={["small"]}
            icon={IoClose}
            tabIndex={open() ? 0 : -1}
          />
          <Dynamic component={getContent() ?? undefined} />
        </section>
      </div>
    </Portal>
  );
};
