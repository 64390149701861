import { stAnalytics } from "@repo/analytics";
import { TbClipboardCopy } from "solid-icons/tb";
import { TiTick } from "solid-icons/ti";
import { type ParentProps, children, createSignal } from "solid-js";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import { DEFAULT_CTA_TRANSITION_DURATION } from "~/ui/micro-interactions";
import styles from "./BlockQuote.module.css";
import { SecondaryTransitionCTA } from "./cta/SecondaryTransitionCTA";

interface Props extends ParentProps {
  class?: string;
}

export const BlockQuote = (props: Props) => {
  const [transition, setTransition] = createSignal(false);
  const elements = children(() => props.children);
  const copy = (event: Event) => {
    event.preventDefault();

    setTransition(true);

    let result = "";
    if (!elements) return;

    elements.toArray().forEach((element) => {
      if (element instanceof Node && element.textContent) {
        result += element.textContent;
      }
    });
    navigator.clipboard.writeText(result);

    setTimeout(() => {
      setTransition(false);
    }, DEFAULT_CTA_TRANSITION_DURATION);

    stAnalytics.track("click_tracking", {
      cta: "copy_clipboard",
      position: "right_drawer",
      screen: getScreenType(),
    });
  };

  return (
    <div class={classNames(styles["blockquote"], props.class)}>
      <blockquote class={classNames(styles["blockquote__text"], "rich-text")}>{props.children}</blockquote>
      <div class={styles["blockquote__actions"]}>
        {/* <SecondaryTransitionCTA
          data-test-id="copy-blockquote-text"
          label="Copy to clipboard"
          accessibleSuffix="the source snippet."
          icon={TbClipboardCopy}
          onClick={copy}
          transition={transition()}
          transitionIcon={TiTick}
          transitionText="Copied!"
        /> */}
        <SecondaryTransitionCTA
          data-test-id="copy-blockquote-text"
          label="Copy to clipboard"
          accessibleSuffix="the source snippet."
          icon={TbClipboardCopy}
          onClick={copy}
          transition={transition()}
          transitionIcon={TiTick}
        />
        {/* <SecondaryCTA
          data-test-id="email-blockquote-text"
          label="Share via email"
          accessibleSuffix="the source snippet."
          icon={BiRegularPaperPlane}
          onClick={copy}
        /> */}
      </div>
    </div>
  );
};
