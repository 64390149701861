import { stAnalytics } from "@repo/analytics/src/analytics";
import { createElementSize } from "@solid-primitives/resize-observer";
import { HiSolidArrowTopRightOnSquare } from "solid-icons/hi";
import { TbBolt, TbKeyframes, TbWorld } from "solid-icons/tb";
import { createEffect, createSignal, on, type Component } from "solid-js";
import { StButton } from "~/components/_original/StButton";
import { Tooltip, TooltipTrigger } from "~/components/_original/Tooltip";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import { truncate } from "~/lib/strings";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useUIState } from "~/ui/UIState";
import { usePromptContext } from "../PromptContext";
import styles from "./ChatScope.module.css";

export const ChatScope: Component<{ test?: true }> = (props) => {
  const ui = useUIState();
  const { promptRef, activeCollection } = usePromptContext();
  const [usePublicKnowledge, setUsePublicKnowledge] = ui.usePublicKnowledge;

  const [parentRef, setParentRef] = createSignal<HTMLDivElement | null>(null);
  const parentSize = createElementSize(parentRef);

  let activeRef!: HTMLDivElement;
  const refs = {
    collection: null as HTMLElement | null,
    public: null as HTMLElement | null,
    both: null as HTMLElement | null,
  };

  const collection = () => activeCollection()?.label;

  const onSwitch = (value: "collection" | "public" | "both") => {
    setUsePublicKnowledge(value);
    stAnalytics.track("click_tracking", {
      cta: "knowledge_scope:v2",
      position: "prompt_bar",
      screen: getScreenType(),
      value,
    });
  };

  // Using a string to easily compare the previous and current values and prevent needless DOM updates
  let lastValues = "";
  createEffect(
    on(
      () => [usePublicKnowledge(), parentSize.width, parentSize.height] as const,
      ([value]) => {
        const el = refs[value];
        if (!el) return;

        const parent = activeRef.parentElement?.getBoundingClientRect();
        const rect = el.getBoundingClientRect();

        const values = [rect.left, rect.width, rect.height, parent?.left].join(",");
        if (lastValues === values) return;

        lastValues = values;
        activeRef.style.transform = `translateX(${rect.left - (parent?.left || 0)}px)`;
        activeRef.style.width = `${rect.width}px`;
        activeRef.style.height = `${rect.height}px`;
      },
    ),
  );

  const documentation = () => (
    <a
      class="inline-block mt-2 hover:underline"
      href="http://docs.storytell.ai/under-the-hood/public-knowledge"
      target="_blank"
      rel="noreferrer"
    >
      <span class="screen-reader">Open documentation to</span>
      <span class="text-blue-600 font-bold">Learn more</span>
      <StIcon icon={HiSolidArrowTopRightOnSquare} class="inline-block ml-1 text-blue-600" size={12} />
      <span class="screen-reader">in a new window</span>
    </a>
  );

  return (
    <div class={styles["parent"]} ref={setParentRef}>
      <div
        role="radiogroup"
        class="flex items-center gap-2 relative rounded bg-gray-200 dark:bg-gray-800 border border-gray-300 dark:border-gray-700"
      >
        <div
          aria-hidden="true"
          ref={activeRef}
          class="absolute top-[0.5px] left-0 shadow bg-violet-700 dark:bg-violet-700 transition rounded"
        />
        <Tooltip
          mount={promptRef()}
          theme="invert"
          content={
            <>
              <p class="max-w-80">
                SmartChat™ will only use your <span class="font-semibold">{collection()}</span> assets to answer. No
                Public Knowledge will be used.
              </p>
              {documentation()}
            </>
          }
          placement="top"
        >
          <TooltipTrigger as="span">
            <StButton
              ref={(el) => {
                refs.collection = el;
              }}
              size="sm"
              onClick={() => {
                onSwitch("collection");
              }}
              class={classNames(
                "w-auto relative hover:bg-transparent dark:hover:bg-transparent",
                usePublicKnowledge() === "collection" && "text-white",
              )}
              role="radio"
              simple
              icon={TbKeyframes}
            >
              <span class="screen-reader">Use only your </span>
              {truncate(collection() ?? "", 19)}
              <span class="screen-reader"> knowledge</span>
            </StButton>
          </TooltipTrigger>
        </Tooltip>

        <Tooltip
          mount={promptRef()}
          theme="invert"
          content={
            <>
              <p class="max-w-80">
                SmartChat™ will use your <span class="font-semibold">{collection()}</span> assets to answer and also
                layer in <span class="font-semibold">Public Knowledge</span> from the LLM's foundational training
                knowledge to augment your answer.
              </p>
              {documentation()}
            </>
          }
          placement="top"
        >
          <TooltipTrigger as="span">
            <StButton
              onClick={() => {
                onSwitch("both");
              }}
              ref={(el) => {
                refs.both = el;
              }}
              size="sm"
              class={classNames(
                "w-auto relative hover:bg-transparent dark:hover:bg-transparent",
                usePublicKnowledge() === "both" && "text-white",
              )}
              role="radio"
              simple
              icon={TbBolt}
            >
              <span class="screen-reader">Use </span>
              {truncate(collection() ?? "", 19)} + Public
              <span class="screen-reader"> knowledge</span>
            </StButton>
          </TooltipTrigger>
        </Tooltip>

        <Tooltip
          mount={promptRef()}
          theme="invert"
          content={
            <>
              <p class="max-w-80">
                SmartChat™ will use <span class="font-semibold">Public Knowledge</span> from the LLM's foundational
                training data to answer. None of your <span class="font-semibold">{collection()}</span> assets will be
                used.
              </p>
              {documentation()}
            </>
          }
          placement="top"
        >
          <TooltipTrigger as="span">
            <StButton
              size="sm"
              ref={(el) => {
                refs.public = el;
              }}
              onClick={() => {
                onSwitch("public");
              }}
              class={classNames(
                "w-auto relative hover:bg-transparent dark:hover:bg-transparent",
                usePublicKnowledge() === "public" && "text-white",
              )}
              role="radio"
              simple
              icon={TbWorld}
            >
              <span class="screen-reader">Use only</span>
              Public Knowledge
            </StButton>
          </TooltipTrigger>
        </Tooltip>
      </div>
    </div>
  );
};
