import { UserAvatar, type UserAvatarProps } from "~/components/UserAvatar";
import styles from "./InlineUserAvatar.module.css";
import { Show, type JSXElement } from "solid-js";

export const InlineUserAvatar = (props: UserAvatarProps & { subtitle?: JSXElement }) => {
  return (
    <p class={styles["inline-user-avatar"]}>
      <span class={styles["inline-user-avatar__portrait"]}>
        <UserAvatar {...props} />
      </span>

      <span class={styles["inline-user-avatar__text-container"]}>
        <span class={styles["inline-user-avatar__name"]}>{props.name}</span>
        <Show when={props.subtitle}>
          <span>{props.subtitle}</span>
        </Show>
      </span>
    </p>
  );
};
