import type { RouteSectionProps } from "@solidjs/router";
import { Match, Show, Suspense, Switch, createMemo, createSignal, onCleanup, onMount, type Component } from "solid-js";
import { AlternativeSideBar } from "~/components/AlternativeSideBar";
import { CollectionsTitleBar } from "~/components/CollectionsTitleBar";
import { CollectionsTop } from "~/components/CollectionsTop";
import { Loading } from "~/components/Loading";
import { MainContentTabs } from "~/components/MainContentTabs";
import { CollectionsModal } from "~/components/containers/CollectionsModal";
import { ContentContainer } from "~/components/containers/ContentContainer";
import { FloatingPromptBarContainer } from "~/components/containers/FloatingPromptBarContainer";
import { RightDrawer } from "~/components/containers/RightDrawer";
import { SkipToContent } from "~/components/cta/SkipToContent";
import { TwoColumnLayout } from "~/components/layouts/TwoColumnLayout";
import { SEOHeaders } from "~/components/meta/SEOHeaders";
import { PersistentPrompt } from "~/domains/chat/prompt/Prompt";
import { PromptContextProvider } from "~/domains/chat/prompt/PromptContext";
import { useIsIdentityConnecting } from "~/domains/identity/hooks";
import type { CampaingPageDataPrompt } from "~/domains/marketing/useUseCasesData";
import { createListResourceOptions } from "~/lib/api/createListResourceOptions";
import { NotFoundScreen } from "~/screens/NotFoundScreen";
import { CollectionAssetsScreen } from "~/screens/subscreen/CollectionAssetsScreen";
import { CollectionChatsScreen } from "~/screens/subscreen/CollectionChatsScreen";
import { CollectionGettingStartedScreen } from "~/screens/subscreen/CollectionGettingStartedScreen";
import { useUIState } from "~/ui/UIState";
import { CustomEvents } from "~/ui/custom-events";
import { useWire } from "~/wire";

type Props = { prompt?: CampaingPageDataPrompt } & RouteSectionProps<unknown>;

export const CollectionScreen: Component<Props> = (props) => {
  const wire = useWire();
  const state = useUIState();
  const [, setTab] = state.mainContentTab;
  const isConnecting = useIsIdentityConnecting();
  const collections = wire.services.collections;
  const threads = wire.services.threads;
  const urlId = () => props.params.collection_id;

  const active = () => collections.getCollection(urlId() || "");

  const [gettingStartedScreenManuallyHidden] = state.gettingStartedScreenManuallyHidden;

  const [tree, { refetch: refetchTree }] = collections.globalResourceTree;
  const [recentThreads] = threads.threadsListResource;

  const [collectionThreads, { refetch: refetchCollectionThreads }] = threads.resourceCollectionThreads(() =>
    isConnecting() ? undefined : urlId(),
  );

  const assetsListOptions = createSignal(createListResourceOptions(1));
  const [assetsOptions] = assetsListOptions;
  const [collectionAssets, { refetch: refetchCollectionAssets }] = collections.resourceCollectionAssets(() =>
    isConnecting() ? undefined : urlId(), assetsOptions
  );

  const rootId = () => collections.getCollectionRoot(urlId() || "")?.id;

  const [collectionAccess, { refetch: refetchCollectionAccess }] = collections.resourceCollectionAccess(() =>
    isConnecting() ? undefined : urlId(),
  );

  const collectionAccessData = () => {
    const col = active();
    const data = collectionAccess()?.data;
    if (!data || !col) return;
    return data.records.filter((r) => col.path.includes(r.collection_id));
  };

  const is404 = () => !tree.loading && active() === undefined;

  // TODO: @andi better way to manage thread state
  onMount(() => {
    wire.services.threads.send(wire.services.threads.eventFactory.newResetEvent());

    CustomEvents.collectionPermissionChange.add(refetchCollectionAccess);
    onCleanup(() => {
      CustomEvents.collectionPermissionChange.remove(refetchCollectionAccess);
    });
  });

  const isUserGettingStarted = createMemo(() => {
    // Checking this so that it doesn't trigger an error boundary
    if (!tree.loading && !tree.error) {
      // If any of the root collections have at least one collection inside, user is not getting started
      for (const col of tree()?.data || []) {
        if ((collections.getChildrenIds(col.collection.id)?.length || 0) > 0) {
          return false;
        }
      }
    }

    // If user has any threads, they're not just getting started
    if ((recentThreads()?.data.result.entities.length || 0) > 1) {
      return false;
    }

    return true;
  });

  return (
    <PromptContextProvider
      initialPrompt={props.prompt?.prompt.name ? { content: props.prompt.prompt.name, highlight: true } : undefined}
      transformationID={props.prompt?.prompt.id}
      autoFocusInput
      hideBackdrop
      activeCollection={active}
      inBackground
      positioning="sticky bottom-0"
      overrideOnFilesUploaded={() => {
        refetchCollectionAssets();
        setTab("Assets");
      }}
    >
      <SEOHeaders title={props.prompt?.prompt.name} description={props.prompt?.prompt.summary} />
      <Show when={!wire.metadata.isDefault}>
        <Suspense>
          {/* <Bug /> */}
          <CollectionsModal accessibleLabel="Permissions menu." />
          <RightDrawer accessibleLabel="Permissions menu." />
          <SkipToContent />
          <TwoColumnLayout
            sidebar={<AlternativeSideBar collectionId={active()?.id} />}
            content={
              <>
                <CollectionsTop showGettingStarted={isUserGettingStarted() && gettingStartedScreenManuallyHidden()} />

                <Switch>
                  <Match when={isConnecting() || tree.loading || recentThreads.loading}>
                    <Loading />
                  </Match>
                  <Match when={is404()}>
                    <NotFoundScreen />
                  </Match>

                  <Match when={!gettingStartedScreenManuallyHidden() && isUserGettingStarted()}>
                    <ContentContainer root>
                      <CollectionGettingStartedScreen
                        refetch={refetchCollectionAssets}
                        assets={collectionAssets()?.data.result.entities.map((a) => a.data) || []}
                      />
                    </ContentContainer>
                  </Match>

                  <Match when={true}>
                    <CollectionsTitleBar
                      access={collectionAccessData()}
                      collectionId={active()?.id || ""}
                      label={active()?.label || ""}
                      description={active()?.description}
                      onCollectionCreated={refetchTree}
                      breadcrumbs={collections.getBreadcrumbs(active()?.id || "")}
                    />
                    <ContentContainer root>
                      <MainContentTabs
                        Chats={
                          <CollectionChatsScreen
                            collection={active()}
                            threads={collectionThreads()?.data.result.entities.map((t) => t.data) || []}
                          />
                        }
                        Assets={
                          <CollectionAssetsScreen
                            collection={active()}
                            assetResponse={collectionAssets()}
                            refreshAssets={refetchCollectionAssets}
                            listOptions={assetsListOptions}
                          />
                        }
                      // KitchenSink={<KitchenSinkScreen />}
                      />
                      <FloatingPromptBarContainer>
                        <PersistentPrompt />
                      </FloatingPromptBarContainer>
                    </ContentContainer>
                  </Match>
                </Switch>
              </>
            }
          />
        </Suspense>
      </Show>
    </PromptContextProvider>
  );
};
