import { stAnalytics } from "@repo/analytics";
import { FaSolidPeopleGroup } from "solid-icons/fa";
import { TbMessagePlus } from "solid-icons/tb";
import { createEffect, Show, type Component } from "solid-js";
import { CollectionBreadcrumbs } from "~/components/CollectionBreadcrumbs";
import { Anchor } from "~/components/cta/Anchor";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import type { CollectionAccessRecord } from "~/domains/collections/collections.types";
import type { AuthenticatedIdentity } from "~/domains/identity/types";
import { useCreateNewSmartchatInCollection } from "~/domains/threads/hooks/useCreateNewSmartchatInCollection";
import { getScreenType } from "~/lib/ui/getScreenType";
import { PermissionsDrawerScreen } from "~/screens/drawer/PermissionsDrawerScreen";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import styles from "./CollectionsTitleBar.module.css";
import { IconCTA } from "./cta/IconCTA";

export const CollectionsTitleBar: Component<{
  label: string;
  description?: string;
  collectionId: string;
  breadcrumbs?: Breadcrumb[];
  onCollectionCreated: (label: string) => void;
  access?: CollectionAccessRecord[];
}> = (props) => {
  const wire = useWire();
  const { activeCollection } = usePromptContext();
  const { onCreateSmartchat } = useCreateNewSmartchatInCollection(() => activeCollection()?.id);
  const state = useUIState();
  const [, setRightOpen] = state.rightDrawer;
  const [, setRightContents] = state.rightDrawerContents;
  const [initiallyOpen, setInitiallyOpen] = state.openShareDrawer;

  const PermissionDrawerContents = () => (
    <PermissionsDrawerScreen access={props.access} collectionId={props.collectionId} />
  );

  const openShareDrawer = () => {
    setRightContents(() => PermissionDrawerContents);
    setRightOpen(true);
  };

  createEffect(() => {
    if (initiallyOpen()) {
      openShareDrawer();
      setInitiallyOpen(false);
    }
  });

  const isItemYou = (item: CollectionAccessRecord) =>
    item.user_id === (wire.services.identity.snapshot.context.identity as AuthenticatedIdentity)?.userId;

  const itemName = (item: CollectionAccessRecord) =>
    isItemYou(item) ? "You" : item.sortableName === "Guest" ? item.email : item.sortableName;

  const withAccess = () =>
    props.access?.filter((r) => r.accessType === "user").sort((a, b) => (isItemYou(a) ? -1 : isItemYou(b) ? 1 : 0));

  return (
    <header data-component="rhs-title" class={styles["collections-title-bar"]}>
      <div class={styles["collections-title-bar__left"]}>
        <CollectionBreadcrumbs max={3} breadcrumbs={props.breadcrumbs || []} limited />
        <h1 class={styles["collections-title-bar__header"]}>
          <span class={styles["collections-title-bar__header-text"]}>{props.label}</span>
          <span class="screen-reader">Collection</span>
        </h1>
        <Show when={props.description}>
          <p class={styles["collections-title-bar__header-description"]}>{props.description}</p>
        </Show>
        <Show when={props.label === "Personal Collection"}>
          <p class={styles["collections-title-bar__header-docs"]}>
            <Anchor
              accessibleSuffix="Visit our documentation to"
              data-test-id="getting-started-collections-learn-more"
              class={styles["collections-title-bar__header-link"]}
              href="https://docs.storytell.ai/features/collections"
              target="_blank"
              label="Learn more about Collections."
              small
              onClick={() => {
                stAnalytics.track("click_tracking", {
                  cta: "documentation",
                  position: "main_content_title",
                  screen: getScreenType(),
                });
              }}
            />
          </p>
        </Show>
        <div class={styles["collections-title-bar__header-create"]}>
          <PrimaryCTA
            label="Chat with this Collection"
            data-test-id="thread-top-create-chat"
            icon={TbMessagePlus}
            onClick={async () => {
              stAnalytics.track("click_tracking", {
                cta: "create_chat",
                position: "main_content_title",
                screen: getScreenType(),
              });
              await onCreateSmartchat();
            }}
            accessibleSuffix="in your current Collection."
          />
        </div>

        {/* <button
          ref={(ref) => {
            setConfigRef(ref);
          }}
          class={styles.collectionsTitleBarConfig}
          type="button"
          aria-controls={configId}
        >
          <BiRegularCog class={styles.collectionsTitleBarHeaderIcon} size="1.75rem" />
          <span class="screen-reader">Configure the Collection.</span>
        </button>
        <CollectionsContextMenu
          onCollectionCreated={props.onCollectionCreated}
          bound={configRef()}
          id={configId}
          collectionId={props.collectionId}
          placement="bottom-start"
        /> */}
      </div>
      <div class={styles["collections-title-bar__right"]}>
        {/* <Suspense>
          <button type="button" class={styles["collections-title-bar__avatars"]} onClick={openShareDrawer}>
            <UserAvatarNest
              max={5}
              avatars={
                withAccess()?.map((r) =>
                  r.sortableName === "Guest"
                    ? {
                        name: itemName(r),
                        isGuest: true,
                        size: "share",
                      }
                    : {
                        name: itemName(r),
                        initial: itemName(r).slice(0, 1),
                        size: "share",
                      },
                ) ?? []
              }
            />
          </button>
        </Suspense> */}
        <Show when={wire.services.collections.getCanShareCollection(props.collectionId)}>
          <ul class={styles["collections-title-bar__menu"]}>
            {/* <li class={styles["collections-title-bar__menu-item"]}>
            <IconShareCTA
              data-test-id="title-star-collection"
              accessibleLabel="Star the Collection."
              isFavorite={true}
            />
          </li> */}
            <li class={styles["collections-title-bar__menu-item"]}>
              <IconCTA
                data-test-id="collections-title-action-menu"
                accessibleLabel="Share this Collection."
                icon={FaSolidPeopleGroup}
                onClick={openShareDrawer}
                modifiers={["secondary-filled"]}
              />
            </li>
          </ul>
        </Show>
      </div>
    </header>
  );
};
