import { FiSave } from "solid-icons/fi";
import { Suspense, createEffect, createSignal, onCleanup, onMount } from "solid-js";
import type { CollectionsModalProps } from "~/components/containers/CollectionsModal";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { LargeInputText } from "~/components/fields/LargeInputText";
import { TextArea } from "~/components/fields/TextArea";
import { useUIState } from "~/ui/UIState";
import { type FormValidationData, useFormValidation } from "~/ui/useFormValidation";
import styles from "./CreateSharedCollectionModal.module.css";
import { StIcon } from "~/components/icons";
import { TbHelpCircleFilled } from "solid-icons/tb";

interface Props extends CollectionsModalProps {
  onCreate?: (name: string, description?: string) => void;
}

export const CreateSharedCollectionModal = (props: Props) => {
  const [modal] = useUIState().modal;
  const [form, setForm] = createSignal((<></>) as HTMLFormElement);
  const [focus, setFocus] = createSignal(false);
  const [canSubmit, setCanSubmit] = createSignal(false);
  const [data, setData] = createSignal<FormValidationData>();
  const [submitting, setSubmitting] = createSignal(false);
  const onSubmit = (event: SubmitEvent) => {
    if (event.target instanceof HTMLFormElement && !submitting()) {
      setSubmitting(true);

      const d = data();
      const name = d?.values.name;
      const description = d?.values.description;
      props.onCreate?.(name || "", description);
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  };

  createEffect(() => {
    if (modal() === props.id) {
      setFocus(true);
    }
    if (modal() === "") {
      setFocus(false);
    }
  });

  onMount(() => {
    setSubmitting(false);
    if (form()) {
      const { validates, data, eventHandler } = useFormValidation(form());
      form().addEventListener("input", eventHandler);
      form().addEventListener("change", eventHandler);
      createEffect(() => {
        setCanSubmit(validates());
        setData(data);
      });

      onCleanup(() => {
        form().removeEventListener("input", eventHandler);
        form().removeEventListener("change", eventHandler);
      });
    }
  });

  return (
    <Suspense>
      <form ref={(ref) => setForm(ref)} class={styles["create-collection-modal"]} onSubmit={onSubmit}>
        <div class={styles["create-collection-modal__title"]}>
          <p class={styles["create-collection-modal__title-text"]}>
            Create a shared collection to collaborate with your team.
            <br />
            After that you can organize your knowledge further by creating sub-collections.
          </p>

          <a
            class={styles["create-collection-modal__title-learn-more"]}
            href="https://docs.storytell.ai/features/collections"
            target="_blank"
            rel="noreferrer"
          >
            <StIcon icon={TbHelpCircleFilled} size={16} />
            Learn more
          </a>
        </div>
        <LargeInputText
          required={true}
          focus={focus}
          placeholder="Choose a name"
          label="Name your new Collection"
          name="name"
          validation={["required", "collectionName"]}
          value=""
          class={styles["create-collection-modal__input"]}
          errorMessage={data()?.messages.name}
          modifier="outline"
        />
        <TextArea
          placeholder="Add a description (optional)"
          label="Add more details for your Collection"
          validation={["collectionDescription"]}
          name="description"
        />
        <div class={styles["create-collection-modal__buttons"]}>
          {/* <p class={styles.createCollectionModalLabel}>Get started with</p> */}
          <div aria-hidden class={styles["create-collection-modal__buttons-left"]}>
            {/* <SecondaryCTA
              data-test-id="create-collections-modal-upload"
              icon={TbShare2}
              label="Upload documents"
              accessibleSuffix="to your Collection"
              class={styles.createCollectionModalButton}
            />
            <SecondaryCTA
              data-test-id="create-collections-modal-chat"
              icon={TbMessage2}
              label="Add Smartchats&trade;"
              accessibleSuffix="to your Collection"
            /> */}
          </div>
          <div class={styles["create-collection-modal__buttons-right"]}>
            <PrimaryCTA
              data-test-id="create-collections-modal-create"
              accessibleSuffix=""
              label="Create Shared Collection"
              type="submit"
              icon={FiSave}
              loading={submitting()}
              inactive={!canSubmit()}
            />
          </div>
        </div>
      </form>
    </Suspense>
  );
};
