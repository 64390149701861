import { TbKeyframes } from "solid-icons/tb";
import { For, Match, type ParentProps, Show, type Signal, Switch } from "solid-js";
import { useWire } from "~/wire";
import styles from "./CollectionsHierarchy.module.css";
import { CollectionsHierarchyItem } from "./CollectionsHierarchyItem";
import type { StIconComponent } from "./icons";
import { getRootCollectionUIOverloads } from "~/domains/collections/collections.helpers";

interface Props extends ParentProps {
  collectionId: string;
  rootCollectionId: string;
  sourceCollectionId: string;
  sourceParentId: string;
  sourceParentHasOptions: boolean;
  depth: number;
  icon?: StIconComponent;
  targetSignal: Signal<string>;
}

export const CollectionsHierarchy = (props: Props) => {
  const wire = useWire();
  const children = () =>
    wire.services.collections
      .getChildren(props.collectionId)
      .sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: "base", ignorePunctuation: true }))
      .filter((entry) => entry.id !== props.sourceCollectionId);
  const collection = () => wire.services.collections.getCollection(props.collectionId);
  const rootOverride = () => getRootCollectionUIOverloads(collection());
  const checkValidity = () => {
    if (props.collectionId === props.sourceParentId && props.sourceParentHasOptions) return true;
    if (props.collectionId !== props.sourceParentId) return true;
    return false;
  };

  return (
    <Show when={checkValidity()}>
      <div class={styles["collections-hierarchy"]} data-empty={children().length === 0}>
        <Switch
          fallback={
            <CollectionsHierarchyItem
              collectionId={collection()?.id ?? ""}
              label={rootOverride()?.label || collection()?.label || ""}
              sourceParentId={props.sourceParentId}
              sourceParentHasOptions={props.sourceParentHasOptions}
              rootCollectionId={props.rootCollectionId}
              depth={props.depth}
              icon={props.icon ?? TbKeyframes}
              initiallyOpen={props.depth === 0}
              targetSignal={props.targetSignal}
            />
          }
        >
          <Match when={children().length > 0}>
            <CollectionsHierarchyItem
              collectionId={collection()?.id ?? ""}
              label={rootOverride()?.label || collection()?.label || ""}
              depth={props.depth}
              icon={props.icon ?? TbKeyframes}
              initiallyOpen={!!rootOverride()}
              targetSignal={props.targetSignal}
              sourceParentId={props.sourceParentId}
              sourceParentHasOptions={props.sourceParentHasOptions}
              rootCollectionId={props.rootCollectionId}
            >
              <For each={children()}>
                {(child) => (
                  <CollectionsHierarchy
                    collectionId={child.id}
                    sourceCollectionId={props.sourceCollectionId}
                    depth={props.depth + 1}
                    icon={TbKeyframes}
                    targetSignal={props.targetSignal}
                    sourceParentId={props.sourceParentId}
                    sourceParentHasOptions={props.sourceParentHasOptions}
                    rootCollectionId={props.rootCollectionId}
                  />
                )}
              </For>
            </CollectionsHierarchyItem>
          </Match>
        </Switch>
      </div>
    </Show>
  );
};
