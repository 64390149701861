import { stAnalytics } from "@repo/analytics";
import { useNavigate, useParams } from "@solidjs/router";
import { TbRefresh } from "solid-icons/tb";
import { type Component, ErrorBoundary, For, Show, startTransition, Suspense } from "solid-js";
import { ListedChat } from "~/components/ListedChat";
import { Loading } from "~/components/Loading";
import { SideBarSectionAccordion } from "~/components/SideBarSectionAccordion";
import { getScreenType } from "~/lib/ui/getScreenType";
import { urls } from "~/lib/urls";
import { useWire } from "~/wire";
import { SecondaryCTA } from "./cta/SecondaryCTA";
import styles from "./SidebarCollections.module.css";

export const SidebarChats: Component = (props) => {
  const wire = useWire();
  const navigate = useNavigate();
  const [threads, { refetch: refetchRecentThreads }] = wire.services.threads.threadsListResource;
  const chats = () => threads()?.data.result.entities.map((t) => t.data);

  // TODO: Better approach
  const threadCollection = (id: string) =>
    wire.services.collections.getCollection(
      (threads()?.data.result.entities.find((t) => t.data.threadId === id)?.collectionIds || []).at(-1) || "",
    )?.label || "";

  const params = useParams();
  const isSelected = (id: string) => {
    return params.thread_id === id;
  };
  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary
        fallback={
          <div class={styles["sidebar-collections__error-boundary"]}>
            <p class={styles["sidebar-collections__error-boundary-text"]}>
              There was an error loading your collections.
            </p>
            <div>
              <SecondaryCTA
                size="small"
                data-test-id="sidebar-collections-error-boundary"
                accessiblePrefix="Click to "
                icon={TbRefresh}
                label="Reload"
                onClick={() => startTransition(() => refetchRecentThreads())}
              />
            </div>
          </div>
        }
      >
        <Show when={(chats() ?? []).length > 0}>
          <SideBarSectionAccordion label="Recent chats" initiallyOpen depth={0}>
            <For each={chats()}>
              {(chat) => (
                <li>
                  <ListedChat
                    onClick={() => {
                      stAnalytics.track("click_tracking", {
                        cta: "navigate_to_chat",
                        position: "left_drawer",
                        screen: getScreenType(),
                      });
                      navigate(urls.threadV2(chat.threadId));
                    }}
                    label={chat.label}
                    collection={threadCollection(chat.threadId)}
                    selected={isSelected(chat.threadId)}
                  />
                </li>
              )}
            </For>
          </SideBarSectionAccordion>
        </Show>
      </ErrorBoundary>
    </Suspense>
  );
};
