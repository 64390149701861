const viewable = [
  "html", "htm", "xhtml", "xml", "svg", "txt", "json",
  "png", "jpg", "jpeg", "gif", "webp", "bmp", "ico",
  "mp4", "webm", "ogg",
  "mp3", "wav", "m4a",
  "pdf"
];

export const isBrowserViewable = (extension: string | undefined) => {
  if (!extension) return false;
  return viewable.includes(extension);
}
