import { TbChevronLeft, TbChevronRight } from "solid-icons/tb";
import { Show, type Signal } from "solid-js";
import { PaginationCTA } from "~/components/cta/PaginationCTA";
import styles from "./Pagination.module.css";
interface Props {
  page: Signal<number>;
  totalCount: number | undefined;
  totalPages: number | undefined;
}

export const Pagination = (props: Props) => {
  const [page, setPage] = props.page;
  return (
    <Show when={props.totalPages}>
      <div class={styles["pagination"]}>
        <div class={styles["pagination__left"]}>
          <PaginationCTA
            data-test-id="collections-assets-prev"
            icon={TbChevronLeft}
            accessibleSuffix="Show previous page of assets from your Collection."
            onClick={() => {
              setPage(page() - 1);
            }}
            inactive={page() === 1}
          />
          <p class={styles["pagination__count"]}>
            <span class={styles["pagination__text"]}>Page&nbsp;</span>
            <span class={styles["pagination__current"]}>{page()}</span>
            <span class={styles["pagination__text"]}>&nbsp;of&nbsp;</span>
            <span class={styles["pagination__total"]}>{props.totalPages}</span>
          </p>
          <PaginationCTA
            data-test-id="collections-assets-next"
            accessibleSuffix="Show next page of assets from your Collection."
            icon={TbChevronRight}
            onClick={() => {
              setPage(page() + 1);
            }}
            inactive={page() === props.totalPages}
          />
        </div>
        <div class={styles["pagination__right"]} />
      </div>
    </Show>
  );
}
