import { stAnalytics } from "@repo/analytics";
import { BiSolidBadgeDollar } from "solid-icons/bi";
import { HiSolidHandRaised, HiSolidRocketLaunch } from "solid-icons/hi";
import { RiBusinessMegaphoneFill } from "solid-icons/ri";
import { Show } from "solid-js";
import { FeedbackForm } from "~/components/_original/Feedback";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";
import { ContextMenu, type ContextMenuProps } from "./ContextMenu";
import type { ContextMenuItemProps } from "./ContextMenuItem";

type Props = Omit<ContextMenuProps, "items" | "signal">;

export const HelpContextMenu = (props: Props) => {
  const wire = useWire();
  const signal = useUIState().helpContextMenu;
  const close = () => signal[1]("");

  const items: ContextMenuItemProps[] = [
    {
      variant: "medium",
      label: "Get Started",
      icon: HiSolidRocketLaunch,
      onClick: () => {
        stAnalytics.track("click_tracking", {
          cta: "documentation",
          position: "header",
          screen: getScreenType(),
          value: "get_started",
        });
        globalThis.open("https://docs.storytell.ai/quickstart", "_blank");
        close();
      },
    },
    {
      variant: "medium",
      label: "Pricing",
      icon: BiSolidBadgeDollar,
      onClick: () => {
        stAnalytics.track("click_tracking", {
          cta: "documentation",
          position: "header",
          screen: getScreenType(),
          value: "pricing",
        });
        globalThis.open("https://go.storytell.ai/pricing", "_blank");
        close();
      },
    },
    {
      variant: "medium",
      label: "Docs & Support",
      icon: HiSolidHandRaised,
      onClick: () => {
        stAnalytics.track("click_tracking", {
          cta: "documentation",
          position: "header",
          screen: getScreenType(),
          value: "docs",
        });
        globalThis.open("https://go.storytell.ai/docs", "_blank");
        close();
      },
    },
    {
      variant: "medium",
      label: "Feedback",
      icon: RiBusinessMegaphoneFill,
      onClick: () => {
        stAnalytics.track("click_tracking", {
          cta: "documentation",
          position: "header",
          screen: getScreenType(),
          value: "feedback",
        });
        wire.services.feedback.openFeedbackPanel();
        close();
      },
    },
    {
      variant: "small-type",
      label: "Terms of Service",
      onClick: () => {
        stAnalytics.track("click_tracking", {
          cta: "documentation",
          position: "header",
          screen: getScreenType(),
          value: "tos",
        });
        globalThis.open("https://go.storytell.ai/tos", "_blank");
        close();
      },
    },
    {
      variant: "small-type",
      label: "Privacy Policy",
      onClick: () => {
        stAnalytics.track("click_tracking", {
          cta: "documentation",
          position: "header",
          screen: getScreenType(),
          value: "privacy_policy",
        });
        globalThis.open("https://go.storytell.ai/privacy", "_blank");
        close();
      },
    },
  ];
  return (
    <>
      <ContextMenu bound={props.bound} id={props.id} items={items} signal={signal} placement="bottom-end" />;
      <Show when={wire.services.feedback.isFeedbackPanelOpen()}>
        <FeedbackForm additionalMetadata={{}} />
      </Show>
    </>
  );
};
