/*
 * Consistent reference to element used for attaching & dispatching.
 */
const element = () => globalThis.document.documentElement;

/*
 * Local function to ensure dispatching of custom events is done on the body element.
 */
const dispatch = (event: CustomEvent) => element().dispatchEvent(event);

/*
 * Reference for naming of custom events.
 */
const GLOBAL_EVENT_NAMES = {
  uploadCompleted: "asset_upload_complete",
  collectionPermissionChange: "collection_permission_change",
} as const;

/*
 * Store for attaching & removing custom DOM event listeners.
 */
export const CustomEvents = {
  uploadCompleted: {
    add: (listener: (event: CustomEvent<UploadCompletedEvent>) => void) =>
      element().addEventListener(GLOBAL_EVENT_NAMES.uploadCompleted, listener as EventListener),
    remove: (listener: (event: CustomEvent<UploadCompletedEvent>) => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.uploadCompleted, listener as EventListener),
    dispatch: (detail?: UploadCompletedEvent) => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.uploadCompleted, {
        bubbles: false,
        cancelable: false,
        detail,
      });
      dispatch(event);
    },
  },
  collectionPermissionChange: {
    add: (listener: () => void) => element().addEventListener(GLOBAL_EVENT_NAMES.collectionPermissionChange, listener),
    remove: (listener: () => void) =>
      element().removeEventListener(GLOBAL_EVENT_NAMES.collectionPermissionChange, listener),
    dispatch: () => {
      const event = new CustomEvent(GLOBAL_EVENT_NAMES.collectionPermissionChange, {
        bubbles: false,
        cancelable: false,
      });
      dispatch(event);
    },
  },
} as const;
