import type { ParentProps } from "solid-js";
import { classNames } from "~/lib/classNames";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import styles from "./ThreadContainer.module.css";

interface Props extends ParentProps {
  class?: string;
}

export const ThreadContainer = (props: Props) => (
  <section
    id={GLOBAL_ELEMENT_IDS.threadContainer}
    {...props}
    class={classNames(styles["thread-container"], props.class)}
  >
    {props.children}
  </section>
);
