import type { assets as assetsNS } from "@repo/client";
import dayjs from "dayjs";
import { HiSolidEye } from "solid-icons/hi";
import { TbAlertCircle, TbDownload } from "solid-icons/tb";
import { TiTick } from "solid-icons/ti";
import { For, Match, Show, Switch, createMemo, createSignal } from "solid-js";
import { Spinner } from "~/components/_original/Spinner";
import { StIcon } from "~/components/icons";
import { InlineAsset } from "~/components/InlineAsset";
import { InlineAssetSummary } from "~/components/InlineAssetSummary";
import { sortObjectArray } from "~/lib/array/sortObjectArray";
import { classNames } from "~/lib/classNames";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { isBrowserViewable } from "~/lib/ui/isBrowserViewable";
import { readableProcessingState } from "~/lib/ui/readableProcessingState";
import styles from "./CollectionAssetTable.module.css";

type Sort<T> = {
  field: keyof T;
  dir: SortDirection;
};

interface Props {
  collectionName: string;
  data: assetsNS.AssetSnapshot[];
  modifier?: "drawer";
}

export const CollectionAssetsTable = (props: Props) => {
  const [sortOrder, setSortOrder] = createSignal<Sort<assetsNS.AssetSnapshot>>({
    field: "modifiedAt",
    dir: "desc",
  });
  const data = createMemo(() => {
    if (!props.data) {
      return [];
    }
    return sortObjectArray<assetsNS.AssetSnapshot>(sortOrder().field, props.data, sortOrder().dir);
  });

  const onClick = (property: keyof assetsNS.AssetSnapshot) => {
    return () => {
      if (sortOrder().field === property) {
        setSortOrder({
          field: property,
          dir: sortOrder().dir === "asc" ? "desc" : "asc",
        });
      } else {
        setSortOrder({
          field: property,
          dir: "asc",
        });
      }
    };
  };
  return (
    <div
      class={classNames(styles["table"], props.modifier ? styles["table--drawer"] : "")}
      data-component="collection-assets-table"
    >
      <table class={styles["table__container"]}>
        <caption class="screen-reader">{`Table representing the assets associated with the ${formatCollectionName(props.collectionName)}.`}</caption>
        <thead class={styles["table__header"]}>
          <tr class={styles["table__header-row"]}>
            <th class={classNames(styles["table__header-cell"], styles["table__cell--source"])}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "originalFilename"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                onClick={onClick("originalFilename")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Source</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={classNames(styles["table__header-cell"], styles["table__cell--status"])}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "lifecycleState"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                onClick={onClick("lifecycleState")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Status</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <th class={classNames(styles["table__header-cell"], styles["table__cell--description"])}>
              <button
                type="button"
                class={classNames(
                  styles["table__header-button"],
                  sortOrder().field === "displayName"
                    ? sortOrder().dir === "asc"
                      ? styles["table__header-button--asc"]
                      : styles["table__header-button--desc"]
                    : "",
                )}
                onClick={onClick("displayName")}
              >
                <span class="screen-reader">Sort table by</span>
                <span class={styles["table__header-label"]}>Description</span>
                <span class={styles["table__sort-indicator"]} />
              </button>
            </th>
            <Show when={!props.modifier}>
              <th class={classNames(styles["table__header-cell"], styles["table__cell--last-mod"])}>
                <button
                  type="button"
                  class={classNames(
                    styles["table__header-button"],
                    sortOrder().field === "modifiedAt"
                      ? sortOrder().dir === "asc"
                        ? styles["table__header-button--asc"]
                        : styles["table__header-button--desc"]
                      : "",
                  )}
                  onClick={onClick("modifiedAt")}
                >
                  <span class="screen-reader">Sort table by</span>
                  <span class={styles["table__header-label"]}>Last modified</span>
                  <span class={styles["table__sort-indicator"]} />
                </button>
              </th>
              <td class={classNames(styles["table__header-cell"], styles["table__cell--actions"])}>
                <span class={styles["table__header-label"]} />
              </td>
            </Show>
          </tr>
        </thead>
        <tbody class={styles["table__body"]} aria-busy={false}>
          <For each={data()}>
            {(item) => {
              return (
                <tr class={styles["table__row"]} tabIndex={0}>
                  <td class={classNames(styles["table__cell"], styles["table__cell--source"])} title={item.originalFilename}>
                    <InlineAsset
                      name={item.originalFilename}
                      mimeType={item.originalFilename?.split(".").pop()?.toLowerCase() || ""}
                    />
                  </td>
                  <td class={classNames(styles["table__cell"], styles["table__cell--status"])}>
                    <Switch>
                      <Match when={readableProcessingState(item.lifecycleState, item.modifiedAt) === "Failed"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--failed"])}>
                          <StIcon class={styles["table__cell-status-icon"]} icon={TbAlertCircle} size="1.25rem" />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState, item.modifiedAt)}
                          </span>
                        </p>
                      </Match>
                      <Match when={readableProcessingState(item.lifecycleState, item.modifiedAt) === "Processing"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--processing"])}>
                          <Spinner class={styles["table__cell-status-icon"]} />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState, item.modifiedAt)}
                          </span>
                        </p>
                      </Match>
                      <Match when={readableProcessingState(item.lifecycleState, item.modifiedAt) === "Ready"}>
                        <p class={classNames(styles["table__cell-status"], styles["table__cell-status--ready"])}>
                          <StIcon class={styles["table__cell-status-icon"]} icon={TiTick} size="1.25rem" />
                          <span class={styles["table__cell-status-text"]}>
                            {readableProcessingState(item.lifecycleState, item.modifiedAt)}
                          </span>
                        </p>
                      </Match>
                    </Switch>
                  </td>
                  <td class={classNames(styles["table__cell"], styles["table__cell--description"])} title={item.displayName}>
                    <InlineAssetSummary summary={item.displayName} />
                  </td>
                  <Show when={!props.modifier}>

                    <td class={classNames(styles["table__cell"], styles["table__cell--last-mod"])}>
                      <span class={styles["table__cell-text"]}>{dayjs(item.modifiedAt).format("LL")}</span>
                    </td>
                    <td class={classNames(styles["table__cell"], styles["table__cell--actions"])}>
                      <Show when={item.signedSourceURL}>
                        <a class={styles["table__cell-action"]} href={item.signedSourceURL} target="_blank" rel="noreferrer">
                          <span class="screen-reader">{isBrowserViewable(item.originalFilename?.split(".").pop()?.toLowerCase()) ? "View file in a new window." : "Download file."}</span>
                          <StIcon aria-hidden="true" icon={isBrowserViewable(item.originalFilename?.split(".").pop()?.toLowerCase()) ? HiSolidEye : TbDownload} size="1.5rem" />
                        </a>
                      </Show>
                    </td>
                  </Show>
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
